const magnify = (image, zoom) => {
  const images = document.querySelectorAll(image)

  const moveMagnifier = (e) => {
    var pos, x, y
    e.preventDefault()
    pos = getCursorPos(e)
    x = pos.x
    y = pos.y
    if (x > img.width - w / zoom) {
      x = img.width - w / zoom
    }
    if (x < w / zoom) {
      x = w / zoom
    }
    if (y > img.height - h / zoom) {
      y = img.height - h / zoom
    }
    if (y < h / zoom) {
      y = h / zoom
    }
    glass.style.left = x - w + 'px'
    glass.style.top = y - h + 'px'
    glass.style.backgroundPosition = '-' + (x * zoom - w + bw) + 'px -' + (y * zoom - h + bw) + 'px'
  }

  const getCursorPos = (e) => {
    var a, x, y, b
    b = e || window.event
    a = img.getBoundingClientRect()
    x = b.pageX - a.left
    y = b.pageY - a.top
    x = x - window.pageXOffset
    y = y - window.pageYOffset
    return {x: x, y: y}
  }

  if (images !== null) {
    for (const el of images) {
      var img, glass, w, h, bw
      img = el
      glass = document.createElement('DIV')
      glass.setAttribute('class', 'c-magnifier__glass')
      img.parentElement.insertBefore(glass, img)
      glass.style.backgroundImage = "url('" + img.src + "')"
      glass.style.backgroundRepeat = 'no-repeat'
      glass.style.backgroundSize = img.width * zoom + 'px ' + img.height * zoom + 'px'
      bw = 3
      w = 200 / 2
      h = 200 / 2
      glass.addEventListener('mousemove', moveMagnifier)
      img.addEventListener('mousemove', moveMagnifier)
      glass.addEventListener('touchmove', moveMagnifier)
      img.addEventListener('touchmove', moveMagnifier)
    }
  }
}

export default magnify
