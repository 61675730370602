/**
 * Essential JS for ContentBuilder
 */
import offCanvasToggle from './offcanvas-trigger'


// Add Offcanvas Trigger
offCanvasToggle('.c-off-canvas__trigger')

/* eslint-disable */
