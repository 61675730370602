// Main JS File
import './partial/contentBuilder';
import './partial/kicktempad';
import imagesLoaded from 'imagesloaded'
import magnify from './partial/magnifier';


if (document.querySelector('.c-magnifier__image') !== null) {
  const initmagnify = () => {
    magnify('.c-magnifier__image', 5)
  }

  new imagesLoaded('.c-magnifier__image', initmagnify)
}
